<template>
  <b-row class="justify-content-md-center">
    <b-col md="6">
      <text-terms-and-condition @redirect="redirect"/>

      <b-modal
        ref="my-modal"
        size="lg"
        centered
        hide-footer
        title="Activa tu cuenta"
      >
        <div class="demo-inline-spacing pb-2">
          <feather-icon icon="MailIcon" size="32" />
          <feather-icon icon="ArrowRightIcon" size="32" />
          <feather-icon icon="CheckCircleIcon" size="32" />
        </div>
        <b-card-text class="text-dark">
          Estás a un paso de poder empezar, ve a tu correo electrónico
          <strong>{{ email }}</strong> y da clic en el correo de confirmación
          para activar tu cuenta.
        </b-card-text>
        <b-card-text
          class="text-dark"
        >
          Revisa tu bandeja de spam en caso que no veas el correo en tu Bandeja
          de Entrada.
        </b-card-text>
        <div class="block">
          <b-button
            @click="resend()"
            variant="no-outline"
            size="lg"
            class="mt-1 no-outline"
            >Reenviar email</b-button
          >
          <b-button @click="next()" variant="primary" size="lg" class="mt-1"
            >Perfecto</b-button
          >
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCardText,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import service from "@/services/others";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import config from "@/services/config";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import TextTermsAndCondition from "./TextTermsAndCondition.vue";
export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BButton,
    TextTermsAndCondition
  },
  data() {
    return {
      clicked: false,
      email: "",
    };
  },
  created() {
    this.email = this.getEmail();
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.clicked = false;
    })
  },
  destroyed() {
    const principal_nav = document.querySelector('ul.nav.navbar-nav.d-xl-none');
    if (principal_nav) principal_nav.style.setProperty('display', 'block', 'important')
    const second_nav = document.getElementsByClassName('width-new')[0];
    if (second_nav) second_nav.style.setProperty('display', 'block', 'important');

    const body = document.querySelector('div.app-content.content');
    body.removeAttribute('style');
  },
  methods: {
    getEmail() {
      const user = JSON.parse(localStorage.userData);
      return user.email;
    },
    getAcceptTC() {
      return localStorage.accept_tyc !== 'false';
    },
    resend() {
      service
        .sendEmailPass({ email: this.email }, true)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Se ha enviado el link a tu correo.",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error al enviar link al correo.",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },

    next() {
      const user = JSON.parse(localStorage.userData);
      const route = getHomeRouteForLoggedInUser(user)
      this.$router.push(route);
    },

    showModal() {
      this.$refs["my-modal"].show();
    },

    redirect() {
      const user = JSON.parse(localStorage.userData);

      this.clicked = true;
      const auth = {
        Authorization: `Bearer ${localStorage.token}`,
      };

      this.$http
        .post(`${config.urlCore}/api/v1/accounts/accept-tyc/`, {
          headers: auth,
        })
        .then((response) => {
          if (response.status !== 200) this.clicked = false;

          localStorage.setItem("accept_tyc", true);

          if (!user.is_verified) {
            this.showModal();
          } else {
            if (user.roll === null) {
              this.$router.push("/roll");
            }
            this.next();
          }
        })
        .catch(() => {
          this.clicked = false;
        });
    },
    deleteAccount() {
      const auth = {
        Authorization: `Bearer ${localStorage.token}`,
      };
      this.$http
        .post(`${config.urlCore}/api/v1/accounts/delete-account/`, {
          headers: auth,
        })
        .then(() => {
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

          localStorage.removeItem("userData");
          localStorage.removeItem("accept_tyc");

          this.$ability.update(initialAbility);

          this.$router.push("/adios");
        })
        .catch(() => {
          this.clicked = false;
        });
    },
  },
};
</script>

<style>
.no-outline {
  color: rgb(164, 164, 164);
}
</style>
